.nav-dropdown {
    -webkit-box-shadow: 0 8px 6px -6px #999;
    -moz-box-shadow: 0 8px 6px -6px #999;
    box-shadow: 0 8px 6px -6px #999;
}

.drop-down-menu {
    background-color: red;
    border-radius: 50px;
    box-shadow: "0px 8px 18px 0 rgba(0,0,0,0.14)";
}