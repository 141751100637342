.img-container {
    position: relative;
    
    max-width: 600px;
  }
 
.overlay {
    position: absolute; 
    bottom: 0; 
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.2); /* Black see-through */
    color: #f1f1f1; 
    width: 100%;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 5px;    
    text-align: center;
  }



.img-container:hover .overlay {
    opacity: 1;
    cursor: pointer;
  }

  .holder {
    cursor: pointer;
  }
 