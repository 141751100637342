.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 80vh;
  }
  
  .Auth-form {
    margin-top: auto;
    margin-bottom: auto;
    max-width: 450px;
    min-width: 350px;
    min-height: 450px;
    box-shadow: rgb(0 0 0 / 16%) 2px 2px 20px;
    /* padding-top: 20px;
    padding-bottom: 10px; */
    border-radius: 10px;
    background-color: white;    
    top: 50%;
    
    position: absolute;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

  }

  .leftbox  {
    flex: 1; 
    padding: 0px;
    margin: 0px;  
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-repeat:no-repeat;
    background-image: url("/public/static/images/home_pic.jpg");
  }

  .rightbox  {
    flex: 1; 
    padding: 40px;
    margin: 5px;  
  }
  
  .Auth-form-content {    
    display: flex;
  }
  
  .Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 800;
  }
  
  label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
  }

  .alink {
  text-decoration: none;
	color: grey;
  font-style: italic;
  }