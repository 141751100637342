.dropdown-wrap {
    padding: 10px;
  }
  .dropdown {
    width: 100%;
    text-align: right;
  }
  .dropdown-trigger {
    width: 100%;
    text-align: right;
  }
  abutton {
    width: 100%;
    text-align: right;
  }
  
.back_box {
  
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.detail_box {
  border: 1px solid gray;
  padding: 10px;

}

.detail_item {
  border: 1px solid gray;
  padding: 10px;
}

.color-item {
  width: 32px;
  height: 32px;
  padding: 4px;
  margin: 2px;
  
  border: 2px solid #d9dcdd;
  background: rgb(239, 238, 238);
}

.color-img {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.my-modal {
  width: 90vw;    /* Occupy the 90% of the screen width */
  max-width: 90vw;
} 

.button-modal {
  width: 60vw;    /* Occupy the 90% of the screen width */
  max-width: 60vw;
} 
buttonhole-modal {
  width: 50vw;    /* Occupy the 90% of the screen width */
  max-width: 50vw;
} 

.inside-fabric {
  width: 50px;
  height: 50px;
  display: block;
  margin: 10px;
  border-radius: 10%;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

