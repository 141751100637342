.holder {
    cursor: pointer;
}

.shirt-info {
  padding: 5px;
}

.my-modal {
  width: 90vw;    /* Occupy the 90% of the screen width */
  max-width: 90vw;
} 