.review {
    margin: auto;
    width: 800px;
    padding: 20px;

}

.orderBorder {
    border: 2px solid red;
    padding:5px;
    border-radius: 5px;
    border: 1px solid gray;
    margin: auto;
    width: 100%;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4)
}

.tab {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 3px;
}

.tab-right {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 3px;
    text-align: right;
}