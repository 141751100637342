.img-container {
    position: relative;
    
    max-width: 700px;
  }
 
.overlay {
    position: absolute; 
    bottom: 0; 
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.1); /* Black see-through */
    color: #f1f1f1; 
    width: 100%;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 5px;    
    text-align: center;
  }



.img-container:hover .overlay {
    opacity: 1;
    cursor: pointer;
  }

  .holder {
    cursor: pointer;
  }
 

  .color-item-sel {
    width: 48px;
    height: 48px;
    padding: 4px;
    margin: 2px;
    border: 2px solid #d9dcdd;
    background: rgb(239, 238, 238);
  }
  
  .color-img-sel {
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  }
  